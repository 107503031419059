import React, { useEffect, useState } from 'react'
import EventDispatcher from "../src/helper/EventDispatcher";
import BaseModal from "../src/Components/Posting/Modal/BaseModal";
import BackendCall from "../src/Components/BackendCall";
import Loader from "../src/Components/icon/Loader";
import Translation from "../src/helper/Translation";
import DefaultButton from "../src/Components/EditProfile/DefaultButton";
import map from "lodash/map"
import { toast } from "react-toastify";
import ImageUploader from "../src/Components/Posting/ImageUploader";
import { Category } from "@shobbak/react-services/dist/Entities";
import { serialize } from "object-to-formdata";


interface PostingInterface  {
  images: []
  imageOptions: {},
  newImages: []
}

const EditPostingMedia = ({ token , locale , postingId  })=> {
  const[isEditPriceEnable , setEditPriceEnable] = useState(false)
  const[errorBag , setErrorBag] = useState({})
  const [isLoading , setIsLoading] = useState(false)
  const [state , setState] = useState<PostingInterface>({ images: []  , imageOptions: {} , newImages: [] })
  const [category , setCategory] = useState<Category| any>({ })
  const [formSubmitted , setFormSubmitted] = useState(false)


  function validateImage(allFiles, bag = {}) {
    const minImageCount = category?.postingValidations?.minimumImages
    const maxImageCount = category?.postingValidations?.maximumImages

    if (minImageCount > allFiles.length || allFiles.length > maxImageCount) {
      let error = Translation.t('create_new_ad.images_count_must_be_between', {
        min: minImageCount,
        max: maxImageCount
      })

      bag['images'] = error
      setErrorBag((prevState) => {
        return {
          ...prevState,
          images: error
        }
      })
    } else {
      setErrorBag((prevState) => {
        delete prevState['images']
        return prevState
      })
    }

    return bag
  }


  const updatePosting = ()=> {
    const bag = validateImage(state.images)
    if (Object.keys(bag).length > 0){
      return ;
    }

    const options = {
      indices: false,
      allowEmptyArrays: false,
    };


    const formData = serialize(
      { image_options: state.imageOptions  , images: state.newImages, submit_for_review: true },
      options
    );

    setFormSubmitted(true)
     BackendCall.i({token , locale})
     .updatePostingMedia(postingId , { data: formData })
       .then(res=> {
         toast.success(Translation.t('texts.ads_updated'));
         setTimeout(()=> window.location.reload() , 800)
       }).catch(()=> setFormSubmitted(false))
  }



  useEffect(()=> {
    EventDispatcher.on('edit-posting-media' , (event)=> {
      setEditPriceEnable(true)
      setIsLoading(true)
      BackendCall.i({token , locale})
      .getPosting(postingId)
        .then(({assetUrls, category})=>  {
          setCategory(category)
          setState({images: assetUrls , imageOptions: {} , newImages: []})
          setIsLoading(false)

        })
    })
  } , [])


  const header = ()=> {
    return (
      <div className={`bg-coolGray-100`}>
         <div className={`flex items-center justify-between  p-4`}>
           <span className={`text-base leading-6 font-semibold text-coolGray-900`}>{Translation.t('screens.edit_post.title')}</span>
           <i className={`ri-close-line text-xl text-coolGray-400 cursor-pointer`}  onClick={()=> setEditPriceEnable(false) }/>
         </div>
      </div>
    );
  }

  const footer = ()=> {
    return (
      <div className={`bg-coolGray-100`}>
        <div className={`flex w-full p-4`}>
          <DefaultButton label={Translation.t('buttons.save')} containerClass={`w-full`} disabled={formSubmitted || Object.keys(errorBag).length > 0}  onButtonClick={()=> { updatePosting() }} />
        </div>
      </div>
    );
  }

  if (!isEditPriceEnable) return <></>

  return (
    <BaseModal onClose={()=>{ setEditPriceEnable(false) }}>
      {isLoading ? <Loader /> :  (
         <div className={`flex flex-col`}>
            {header()}
            <div className={`px-4 py-4 flex flex-col gap-2`}>
                <label htmlFor="cover-photo" className="text-base leading-6 text-semibold text-coolGray-900">
                  {Translation.t('create_new_ad.ad_images')}
                </label>

                <ImageUploader state={state} dispatch={({payload})=> {
                  const removeFiles = state.imageOptions
                  const newRemoveFile = payload.find(({key})=> key == 'image_option_detail' )?.value
                  if(newRemoveFile){
                    removeFiles[newRemoveFile.toString()] = { remove: true }
                  }

                  const newImagesState = payload.find(({key})=> key == 'images')?.value

                  const newFiles = newImagesState.filter(item => item instanceof  File)

                  setState({ images: newImagesState , newImages: newFiles , imageOptions: removeFiles })
                }} validateImage={(files) => {
                  validateImage(files)
                }} />

              </div>
              <div>
                {errorBag['images'] && <span
                  className="text-red-500 text-xs flex flex-start leading-4 font-normal mt-1">{errorBag['images']}</span>}
              </div>
           {footer()}
         </div>
      )  }

    </BaseModal>
  )
}

export default EditPostingMedia
