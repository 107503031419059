import React, { useEffect, useRef, useState } from 'react'

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import BaseModal from '../src/Components/Posting/Modal/BaseModal';
import Loader from '../src/Components/icon/Loader';
import BackendCall from '../src/Components/BackendCall';
import Input from '../src/Components/Input';
import Translation from '../src/helper/Translation';
import ShobbakSuccessIcon from '../src/Components/icon/ShobbakSuccessIcon';
import ShobbakErrorIcon from '../src/Components/icon/ShobbakErrorIcon';

interface BannersProps {
  banners: Banner[];
  token: string;
  locale: string;
  countryCode: string;
  isLoggedIn: boolean;
}

interface Banner {
  id: number;
  attributes: {
    title: string;
    banner_image_url: string;
    banner_web_image_url: string;
    banner_form_id: number;
    third_party_type?: 'quantum';
    third_party_url?: string;
  }
}

interface BannerForm {
  id: number;
  title: string;
  description: string;
  canReply: boolean;
  bannerFormFields: BannerFormField[];
  banner?: Banner
}

interface BannerFormField {
  id: number;
  key: string;
  title: string;
  description: string;
  placeholder: string;
  fieldType: 'string' | 'number' | 'mobile_number' | 'email' | 'select_dropdown';
  isRequired: boolean;
  weight: number;
  bannerFormFieldValues?: BannerFormFieldValue[];
}

interface BannerFormFieldValue {
  id: number;
  key: string;
  value: string;
  weight: number;
}

type FormStatus = 'in_progress' | 'success';

export default function Banners({ banners, token, locale, countryCode, isLoggedIn }: BannersProps) {
  const [bannersState, setBannersState] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchingBanners, setIsFetchingBanners] = useState(false);
  const [bannerForm, setBannerForm] = useState<BannerForm>(null);
  const bannerFormRef = useRef<BannerForm>(null)
  const [formData, setFormData] = useState({});
  const [errorBag, setErrorBag] = useState([]);
  const [formStatus, setFormStatus] = useState<FormStatus>('in_progress');
  const [trackedBanners, setTrackedBanners] = useState([]);
  const errorContainer = useRef();

  useEffect(() => {
    if (banners.length > 0) {
      fetchAndSetThirdPartyBanners(banners)
    }
  }, [])

  useEffect(() => {
    if(bannersState.length > 0) {
      trackBannerImpression(0)
    }
  }, [bannersState])

  useEffect(() => {
    if (!bannerForm) return

    bannerFormRef.current = bannerForm
    let data = {}
    bannerForm.bannerFormFields.forEach(f => data[f.key] = null)
    setFormData(data)
  }, [bannerForm])

  // @TODO: refactor later to one api all after quantum team fix/enhance their apis.
  async function fetchAndSetThirdPartyBanners(banners) {
    setIsFetchingBanners(true)
    const replacedWithThirdPartyBanners = await Promise.all(banners.map(async (banner) => {
      if (banner.attributes.third_party_type === 'quantum') {
        try {
          const response = await fetch(banner.attributes.third_party_url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Accpet': 'application/json'
            },
            body: JSON.stringify({
              keywords: "",
              width: window.innerWidth,
              height: window.innerHeight,
              lang: locale?.toUpperCase()
            })
          })

          const third_party_data = await response.json()

          if (!third_party_data.ads?.length) {
            return null
          }

          const ad = third_party_data.ads[0]
          banner.attributes = {
            ...banner.attributes,
            banner_image_url: ad.imageUrl,
            banner_web_image_url: ad.imageUrl,
            title: ad.htmlAltText,
            web_url: ad.redirectUrl
          }
        } catch(e) {
          console.log(e)
        }

      }

      return banner
    }));

    setBannersState(replacedWithThirdPartyBanners.filter((value) => value != null))
    setIsFetchingBanners(false)
  }

  function handleBannerClick(banner: Banner) {
    window.trackActivity({ event: 'banner_click', rawPayload: { banner_id: banner.id, banner_title: banner.attributes.title, banner_form_id: banner.attributes.banner_form_id }, shouldRedirect: false, processPayload: false })

    if (banner.attributes.web_url) {
      return window.open(banner.attributes.web_url, '_blank')
    }

    if (banner.attributes.banner_form_id && isLoggedIn) {
      setIsLoading(true);
      setIsOpen(true);
      BackendCall.i({ token, locale }).getBannerForm(banner.attributes.banner_form_id)
        .then(res => {
          setIsLoading(false)
          setBannerForm({...res, banner})
        })
        .catch(err => {
          // 
        });
    }
  }

  function updateFormData(key: string, value: any) {
    setFormData({ ...formData, [key]: value })
  }

  function submit() {
    BackendCall.i().replyToBannerForm(bannerForm.id, formData)
      .then(res => {
        setFormStatus('success')
        trackBannerFormSuccessfulSubmission(bannerForm.banner)
      })
      .catch(error => {
        errorContainer.current?.scrollIntoView()
        setErrorBag(error?.errors || [])
      })
  }

  function resetForm() {
    setIsOpen(false)
    setFormData({})
    setErrorBag([])
    setFormStatus('in_progress')
    setBannerForm(null)
  }

  function trackBannerImpression(bannerIndex: number) {
    if (trackedBanners.includes(bannersState[bannerIndex].id) == false) {
      const banner = bannersState[bannerIndex]
      setTrackedBanners([...trackedBanners, banner.id])
      window.trackActivity({ event: 'banner_impression', rawPayload: { banner_id: banner.id, banner_title: banner.attributes.title, banner_form_id: banner.attributes.banner_form_id }, shouldRedirect: false, processPayload: false })
    }
  }

  function trackBannerFormSuccessfulSubmission(banner: Banner) {
    window.trackActivity({ event: 'banner_form_submitted_successfully', rawPayload: { banner_id: banner.id, banner_title: banner.attributes.title, banner_form_id: banner.attributes.banner_form_id }, shouldRedirect: false, processPayload: false })
  }

  function trackBannerFormCloseWithoutSubmission(banner: Banner) {
    window.trackActivity({ event: 'banner_form_closed_without_submission', rawPayload: { banner_id: banner.id, banner_title: banner.attributes.title, banner_form_id: banner.attributes.banner_form_id }, shouldRedirect: false, processPayload: false })
  }

  function closeForm() {
    if (formStatus === 'in_progress') {
      trackBannerFormCloseWithoutSubmission(bannerFormRef.current.banner)
    }
    resetForm();
  }

  function renderBannerFormModal() {
    if (!isOpen) return null;

    if (bannerForm && bannerForm.canReply === false) {
      return (
        <BaseModal onClose={resetForm}>
          <div className={`flex flex-col p-4`}>
            <i className={`ri-close-line text-xl text-coolGray-400 cursor-pointer`} onClick={() => setIsOpen(false)} />
            <div className="flex items-center justify-center mb-4">
              <ShobbakErrorIcon />
            </div>
            <div className="mb-4 flex flex-col items-center justify-center text-center p-2">
              <p className="text-xl leading-7 font-bold text-red-500 mb-1">{Translation.t('texts.banner_form_cannot_reply_title')}</p>
              <p className="text-base leading-6 font-normal text-coolGray-600">{Translation.t('texts.banner_form_cannot_reply_subtitle')}.</p>
            </div>
            <div>
              <button onClick={() => setIsOpen(false)} className="w-full py-2 rounded-lg text-white font-normal text-lg bg-emerald-500">{Translation.t('buttons.got_it')}</button>
            </div>
          </div>
        </BaseModal>
      )
    }

    if (formStatus == 'success') {
      return (
        <BaseModal onClose={resetForm}>
          <div className={`flex flex-col p-4`}>
            <i className={`ri-close-line text-xl text-coolGray-400 cursor-pointer`} onClick={resetForm} />
            <div className="flex items-center justify-center mb-4">
              <ShobbakSuccessIcon />
            </div>
            <div className="mb-4 flex flex-col items-center justify-center text-center p-2">
              <p className="text-xl leading-7 font-bold text-coolGray-800 mb-1">{Translation.t('texts.banner_form_reply_success_title')}</p>
              <p className="text-base leading-6 font-normal text-coolGray-600">{Translation.t('texts.banner_form_reply_success_subtitle')}</p>
            </div>
            <div>
              <button onClick={resetForm} className="w-full py-2 rounded-lg text-white font-normal text-lg bg-emerald-500">{Translation.t('buttons.got_it')}</button>
            </div>
          </div>
        </BaseModal>
      )
    }

    return (
      <BaseModal onClose={closeForm}>
        <div dir={locale == "ar" ? "rtl" : "ltr"} className={`flex items-center justify-between p-4`}>
          <i className={`ri-close-line text-xl text-coolGray-400 cursor-pointer`} onClick={closeForm} />
        </div>
        <div className="px-4 pb-4" dir={locale == "ar" ? "rtl" : "ltr"}>
          {isLoading ? <Loader /> : (
            <div className="p-2">
              <div className="flex flex-col">
                {bannerForm != null && bannerForm.title && (<p className="text-start font-semibold text-base text-coolGray-700 leading-7">{bannerForm?.title}</p>)}
                <p className="text-start font-bold text-xl text-coolGray-700 leading-7">{Translation.t('texts.banner_form_title')}</p>
                <div className="flex items-center">
                  <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.49967 13.6663C3.81767 13.6663 0.833008 10.6817 0.833008 6.99967C0.833008 3.31767 3.81767 0.333008 7.49967 0.333008C11.1817 0.333008 14.1663 3.31767 14.1663 6.99967C14.1663 10.6817 11.1817 13.6663 7.49967 13.6663ZM6.83301 6.33301V10.333H8.16634V6.33301H6.83301ZM6.83301 3.66634V4.99967H8.16634V3.66634H6.83301Z" fill="#374151" />
                  </svg>
                  <p className="text-start ms-2 font-normal text-base leading-6 text-coolGray-500">{Translation.t('texts.banner_form_subtitle')}</p>
                </div>
              </div>
              <div className="overflow-y-auto pb-10" style={{ height: '50vh' }}>
                <ul className={`${errorBag.length > 0 ? 'mb-2 bg-red-50 p-2 rounded-lg' : ''}`} ref={errorContainer}>
                  {errorBag.length > 0 && (
                    errorBag.map(error => {
                      return (
                        <li className="text-sm text-red-500">{error.detail}</li>
                      )
                    })
                  )}
                </ul>
                {bannerForm?.bannerFormFields.sort((a, b) => b.weight - a.weight).map(renderBannerFormField)}
              </div>
              <div className="pt-4">
                <button className="w-full bg-emerald-500 text-white rounded-lg py-2" onClick={submit}>{Translation.t('buttons.submit')}</button>
              </div>
            </div>
          )}
        </div>
      </BaseModal>
    )
  }

  function renderBannerFormField(field: BannerFormField) {
    const { fieldType } = field
    if (fieldType == "string") {
      return (
        <div key={field.id} className="mb-4">
          <label htmlFor={`field-${field.id}`} className="block w-full text-start text-base leading-6">{field.title} {field.isRequired ? <span className="text-red-500 text-sm">*</span> : null}</label>
          <Input id={`field-${field.id}`} required={field.isRequired} placeholder={field.placeholder} containerStyle="my-1 w-full" onChange={(e) => { updateFormData(field.key, e.target.value) }} />
          {errorBag[field] && errorBag[field.key] && (<span className="text-red-500 text-sm">{errorBag[field.key]}</span>)}
        </div>
      )
    } else if (fieldType == "number") {
      return (
        <div key={field.id} className="mb-4">
          <label htmlFor={`field-${field.id}`} className="block w-full text-start text-base leading-6">{field.title} {field.isRequired ? <span className="text-red-500 text-sm">*</span> : null}</label>
          <Input id={`field-${field.id}`} required={field.isRequired} type="number" placeholder={field.placeholder} containerStyle="my-1 w-full" onChange={(e) => { updateFormData(field.key, e.target.value) }} />
        </div>
      )
    } else if (fieldType == "email") {
      return (
        <div key={field.id} className="mb-4">
          <label htmlFor={`field-${field.id}`} className="block w-full text-start text-base leading-6">{field.title} {field.isRequired ? <span className="text-red-500 text-sm">*</span> : null}</label>
          <Input id={`field-${field.id}`} required={field.isRequired} type="email" placeholder={field.placeholder} containerStyle="my-1 w-full" onChange={(e) => { updateFormData(field.key, e.target.value) }} />
        </div>
      )
    } else if (fieldType == "mobile_number") {
      return (
        <div key={field.id} className="mt-2 mb-4">
          <label htmlFor={`field-${field.id}`} className="block w-full text-start text-base leading-6">{field.title} {field.isRequired ? <span className="text-red-500 text-sm">*</span> : null}</label>
          <div className="mt-1 flex border rounded-md overflow-hidden focus-within:ring-1 focus-within:ring-coolGray-500  shadow-sm focus:outline-none">
            <select className=" sm:text-sm  border-gray-300 border-e border-t-0 bg-coolGray-50 text-base leading-6 text-coolGray-500 text-normal border-b-0 border-s-0 focus:ring-0 focus:outline-none focus:border-coolGray-500" disabled>
              <option>{countryCode}</option>
            </select>
            <input type="text" required={field.isRequired} id={`field-${field.id}`} className="block px-3 w-full sm:text-sm border-0 border-white focus:outline-none focus:ring-0 focus:border-white" placeholder={field.placeholder} onChange={(e) => { updateFormData(field.key, e.target.value) }} />
          </div>
        </div>
      )
    } else if (fieldType == "select_dropdown") {
      return (
        <div key={field.id} className="mb-4">
          <label htmlFor={`field-${field.id}`} className="block w-full text-start text-base leading-6">{field.title} {field.isRequired ? <span className="text-red-500 text-sm">*</span> : null}</label>
          <select id={`field-${field.id}`} className="banner-select-dropdown my-1 w-full h-12 form-input border border-coolGray-300 block py-2 px-3 rounded-md shadow-sm focus:outline-none focus:ring-coolGray-300 focus:border-coolGray-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            onChange={(e) => { updateFormData(field.key, e.target.value) }}>
            <option value={null}>{field.placeholder}</option>
            {field?.bannerFormFieldValues?.sort((a, b) => b.weight - a.weight).map((option) => {
              return (<option key={option.id} value={option.key}>{option.value}</option>)
            })}
          </select>
        </div>
      )
    }

    return null;
  }

  if (isFetchingBanners) return <div className="md:h-56 h-32 flex items-center justify-center overflow-hidden"><Loader /></div>

  return (
    <div style={{ direction: 'initial' }}>
      <Carousel
        autoPlay={true}
        infiniteLoop={true}
        showArrows={false}
        showStatus={false}
        showThumbs={false}
        onChange={(index) => {
          trackBannerImpression(index)
        }}
      >
        {bannersState.map((banner) => {
          return (
            <div key={banner.id} className="md:h-56 h-32 w-full rounded-lg cursor-pointer" onClick={() => handleBannerClick(banner)}>
              <img className="rounded-lg h-full w-full bg-no-repeat bg-cover bg-center cursor-pointer" src={banner.attributes.banner_web_image_url ? banner.attributes.banner_web_image_url : banner.attributes.banner_image_url} alt={banner.attributes.title} />
            </div>
          )
        })}
      </Carousel>

      {renderBannerFormModal()}
    </div >
  )
}
