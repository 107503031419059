import React from 'react'
import Translation from '../../helper/Translation';
import Button from '../Button';
import MessageDetailsModal from './MessageDetailsModal';

type CompProps = {
  message: {
    backgroundColor: string;
    description: string;
    foregroundColor: '#10B981';
    icon: string;
    title: string;
    readMore: boolean;
    files: any[];
    comment: string;
    linkTitle: string;
    link: string;
  };
};

const StatusMessage = ({ message }: CompProps) => {
  const [detailsVisible, setDetailsVisible] = React.useState<boolean>(false);

  return (
    <>
      <div className="md:p-4 p-2 rounded-lg w-full" style={{ backgroundColor: message.backgroundColor }}>
        <div className="flex gap-1 w-full">
          <i className={`ri-${message.icon} ri-2x`} style={{ color: message.foregroundColor }} />
          <div className="flex flex-col">
            <p className={`text-base leading-5 font-semibold`} style={{ color: message.foregroundColor }}>{message.title}</p>
            <p className={`text-sm leading-5 font-normal`} style={{ color: message.foregroundColor }}>{message.description}</p>
          </div>
        </div>

        {message.readMore && (
          <Button
            size="small"
            color="white"
            labelColor="text-coolGray-700"
            containerStyle="w-full mt-2"
            label={Translation.t('dispute.actions.read_more')}
            onPress={() => {
              setDetailsVisible(true);
            }}
          />
        )}

        {message.link && (<a href={message.link} target="_blank" className="block text-center w-full bg-white text-coolGray-800 font-bold rounded-lg py-2 mt-2"> {message.linkTitle} </a>)}
      </div>

      <MessageDetailsModal
        message={message}
        visible={detailsVisible}
        onClose={() => {
          setDetailsVisible(false);
        }}
      />
    </>
  )
}

export default StatusMessage
